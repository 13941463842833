import React from "react";
import "../css/index.css"
import styles from "../css/Queries.module.css";
import OrganizerCard from "../components/OrganizerCard";
import { getSingleDoc } from "../config/firebaseFirestore";
import { useState, useEffect } from "react";
import Loader from "../components/Loader";

const Queries = () => {
  const [organizers, setOrganizers] = useState([]);
  const [loading, setLoading] = useState(true);
  
    const fetchData = async () => {
      const organizerData = await getSingleDoc("WebContentsNew", "NewQueries");
      let organizers = [];
  
      for (let i = 0; i < organizerData.data.length; i++) {
        switch (organizerData.data[i].Dept.content) {
          case "Hospitality and Transport":
            organizers.push(organizerData.data[i]);
            break;
          default:
            console.log("Unknown Flag");
            break;
        }
      }
      setLoading(false);
      setOrganizers(organizers);
    };
  
    useEffect(() => {
      fetchData();
    }, []);
  
    if (loading) {
      return <Loader />;
    }
  
    return (
      <div className={styles.container}>
        <h1 className={styles.mainHeading}>For Queries, Please Contact</h1>
      <div className={styles.organizersSection}>
        {organizers.map((organizer, index) => (
          <div className={styles.organizerCard} key={index}>
            <OrganizerCard organizer={organizer} />
          </div>
        ))}
      </div>
    </div>
    );    
  };
  
  export default Queries;
  
import { useState, useEffect } from "react";
import styles from '../css/AboutAntaragni.module.css';
import AnimatedDiv from "./AnimatedDiv";
import CountUp from "react-countup";

function AboutAntaragni() {
    const [selectedYear, setSelectedYear] = useState(2023);
    const [countKey, setCountKey] = useState(0); 

    const links = {
        2022: "https://www.youtube.com/embed/boF7X5yBEXo?si=0uo73lUCqeK-EzOf",
        2021: "https://www.youtube.com/embed/qZmtHBF0AKQ?si=p0N76On1zYzxN23x",
        2018: "https://www.youtube.com/embed/hQQLEFmkqwg?si=be3JGjx1rDpWlOL5",
        2017: "https://www.youtube.com/embed/zFtS63KKhvc?si=Vim71YV3G08xiebJ",
        2019: "https://www.youtube.com/embed/cMoHcuD6vOQ?si=dzJW99oqLeSYdb6L",
        2023: "https://www.youtube.com/embed/EDfXjGl1kys?si=CumkenpxHZvqZF_s",
    };

    const handleYearClick = (year, event) => {
        event.preventDefault();
        setSelectedYear(year);
    };

    const handleClick = () => {
        window.open(links[selectedYear]);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCountKey((prevKey) => prevKey + 1); 
        }, 12000); 

        return () => clearInterval(interval); 
    }, []);

    return (
        <AnimatedDiv>
            <div className={styles.about}>
                <div className={styles.left}>
                    <p className={styles.heading}>AFTERMOVIES</p>
                    <div className={styles.year}>
                        {Object.keys(links).map((year) => (
                            <div
                                key={year}
                                onClick={(e) => handleYearClick(Number(year), e)}
                                className={selectedYear === Number(year) ? styles.active : ''}
                            >
                                {year}
                            </div>
                        ))}
                    </div>
                    <div className={styles.video}>
                        <iframe
                            width="100%"
                            height="100%"
                            src={links[selectedYear]}
                            title="Antaragni Aftermovie"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <p className={styles.bottom} onClick={handleClick}>Watch On Youtube</p>
                </div>
                <div className={styles.right}>
                    <p className={styles.heading}>ABOUT ANTARAGNI</p>
                    <p className={styles.para}>
                        Antaragni - The Annual Cultural Festival Of IIT Kanpur Is One Of The
                        Largest And Most Anticipated Festivals Throughout Asia. Translated
                        Literally To The Fire Within, Everyone Associated Will Feel Inside.
                        Continuing Its Rich Legacy Of 58 Years, Antaragni Combines The Zeal
                        And Desire Of The Students Of IIT Kanpur And Across India To
                        Showcase And Celebrate Their Amazing Cultural Talent As It Has
                        Evolved To Be A Platform For Global Cultural Interaction Over The
                        Years. It Has Been The Touchstone Of India's College Cultural
                        Heritage.
                    </p>
                    <div className={styles.bottomfield}>
                        <div className={styles.cont}>
                            <p className={styles.rotatingnumber}>
                                <CountUp key={countKey} start={0} end={150} duration={6} delay={0} />+
                            </p>
                            <p>Corporates</p>
                        </div>
                        <div className={styles.cont}>
                            <p className={styles.rotatingnumber}>
                                <CountUp key={countKey} start={0} end={60} duration={6} delay={0} />+
                            </p>
                            <p>Govt. Orgs</p>
                        </div>
                        <div className={styles.cont}>
                            <p className={styles.rotatingnumber}>
                                <CountUp key={countKey} start={0} end={350} duration={6} delay={0} />+
                            </p>
                            <p>Colleges</p>
                        </div>
                        <div className={styles.cont}>
                            <p className={styles.rotatingnumber}>
                                <CountUp key={countKey} start={0} end={75} duration={6} delay={0} />+
                            </p>
                            <p>Events</p>
                        </div>
                        <div className={styles.cont}>
                            <p className={styles.rotatingnumber}>
                                <CountUp key={countKey} start={0} end={140} duration={6} delay={0} />K+
                            </p>
                            <p>Footfall</p>
                        </div>
                        <div className={styles.cont}>
                            <p className={styles.rotatingnumber}>
                                <CountUp key={countKey} start={0} end={30} duration={6} delay={0} />M+
                            </p>
                            <p>Eyeballs</p>
                        </div>
                    </div>
                </div>
            </div>
        </AnimatedDiv>
    );
}

export default AboutAntaragni;

// import { useRef } from "react";
// import { useGLTF } from "@react-three/drei";
// import { eiffel } from "../../assets";

// export default function Eiffel({ position = [0, -0.001, -0.05], rotation = [0, 0, 0], scale = [0.01, 0.01, 0.01] }) {
//     const { nodes } = useGLTF(eiffel)
//     const modelRef = useRef()

//     return (
//         <group ref={modelRef} position={position} rotation={rotation} scale={scale}>
//             {
//                 Object.keys(nodes).map((key) => {
//                     const node = nodes[key]
//                     if (node.isMesh) {
//                         return (
//                             <mesh
//                                 key={key}
//                                 geometry={node.geometry}
//                                 material={node.material}
//                                 position={node.position}
//                                 scale={node.scale}
//                             />
//                         )
//                     }
//                 })
//             }
//         </group>
//     )
// }

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { eiffel } from '../../assets'

export default function Eiffel({ position = [0, 0, -0.03], rotation = [0, 0, 0]}) {
  const { nodes, materials } = useGLTF(eiffel)
  return (
    <group position={position} rotation={rotation} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Tour_eiffel.geometry}
        material={materials['Material.001']}
        scale={[0.0012,0.0013, 0.00125]}
      />
    </group>
  )
}

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { pyramid_comp } from '../../assets'

export default function Sphinx({ position = [0.08, 0.035, -0.003], rotation = [Math.PI, Math.PI, 0], scale = [0.012, 0.012, 0.012] }) {
  const { nodes, materials } = useGLTF(pyramid_comp)
  const modelRef = useRef()
  return (
    <group ref={modelRef} position={position} rotation={[rotation[0], rotation[1], rotation[2]]} scale={scale}>
      {
                Object.keys(nodes).map((key) => {
                    const node = nodes[key]
                    if (node.isMesh) {
                        return (
                            <mesh
                                key={key}
                                geometry={node.geometry}
                                material={node.material}
                                position={node.position}
                                scale={node.scale}
                            />
                        )
                    }
                })
            }
    </group>
  )
}
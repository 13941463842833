import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styles from "../css/ModeSwitch.module.css"

const ModeSwitch = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [mode, setMode] = useState(location.pathname === "/3d" ? "Normal Mode" : "3D Mode")

    useEffect(() => {
        setMode(location.pathname === "/3d" ? "Normal Mode" : "3D Mode")
    })

    const handleClick = () => {
        console.log(mode)
        navigate(mode === "3D Mode" ? "/3d" : "/")
        setMode(mode === "3D Mode" ? "Normal Mode" : "3D Mode")
    }
    return (
        
        <button className={`${styles.switch} ${location.pathname === "/3d" && styles.threed}`} onClick={handleClick}>
            {location.pathname === "/3d" && mode}
        </button>
    )
}

export default ModeSwitch
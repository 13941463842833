import React, { useState, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';

const Model = ({ url, scale }) => {
    const { scene, error } = useGLTF(url);
    const width = window.screen.width;
    const [Position, setPosition] = useState([0, -10.2, 0]);
    useEffect(() => {
        if (width < 500) {
            setPosition([0,-7.7,0]);
        } else {
            setPosition([0, -10.2, 0]);
        }
    }, [width]);
    if (error) {
        console.error("Error loading model:", error);
        return null;
    }

    return <primitive object={scene} scale={scale}  position={Position}/>;
};

export default Model;

import { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { unity } from "../../assets";

export default function UnityStatue({ position =[0.04, -0.04, 0.08], rotation = [Math.PI/2, Math.PI, Math.PI/2], scale = [0.0055, 0.0055, 0.0055] }) {
    const { nodes } = useGLTF(unity)
    const modelRef = useRef()

    return (
        <group ref={modelRef} position={position} rotation={rotation} scale={scale}>
            {
                Object.keys(nodes).map((key) => {
                    const node = nodes[key]
                    if (node.isMesh) {
                        return (
                            <mesh
                                key={key}
                                geometry={node.geometry}
                                material={node.material}
                                position={node.position}
                                scale={node.scale}
                            />
                        )
                    }
                })
            }
        </group>
    )
}
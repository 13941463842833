import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import styles from "../css/DiscoverMore.module.css";

const DiscoverMore = ({ data }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setScrollPosition((prev) => (prev + 1)); 
      }, 2000); 

      return () => clearInterval(interval);
    }
  }, [isPaused, data.length]);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  const handleOpen = (link) => {
    window.open(link);
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>DISCOVER MORE</div>
      <div className={styles.carousel} ref={containerRef}>
        {data.map((src, index) => (
          <motion.div
            key={index}
            className={styles["carousel-item"]}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            initial={{
              opacity: 0,
              transform: `rotateY(${index * (360 / data.length)}deg) translateZ(-750px)`
            }}
            animate={{
              opacity: 1,
              transform: `rotateY(${(index - scrollPosition) * (360 / data.length)}deg) translateZ(-750px)`
            }}
            transition={{ duration: 1, ease: "easeInOut" }}
          >
            <img src={src.pic.content.url} alt={`Carousel ${index + 1}`} onClick={() => handleOpen(src.Link.content)} />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default DiscoverMore;

import { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { confetti } from "../../assets";

export default function Confetti({position=[-0.04, 0.025, -0.085], rotation=[0, 0, Math.PI], scale=[0.005, 0.005, 0.005]}) {
    const {nodes} = useGLTF(confetti)
    const modelRef = useRef()

    return (
        <group ref={modelRef} position={position} rotation={rotation} scale={scale}>
            {
                Object.keys(nodes).map((key) => {
                    const node = nodes[key]
                    if(node.isMesh){
                        return (
                            <mesh 
                                key={key}
                                geometry={node.geometry}
                                material={node.material}
                                position={node.position}
                                scale={node.scale}
                            />
                        )
                    }
                })
            }
        </group>
    )
}
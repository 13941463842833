import { useFrame, useThree } from "@react-three/fiber";
import America from "./America";
import China from "./China";
import Egypt from "./Egypt";
import Europe from "./Europe";
import India from "./India";
import Ship from "./Ship";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { Billboard, Bounds, Cloud, Clouds, Html, Sparkles, Stars, Text, useBounds, useTexture } from "@react-three/drei";
import { useStore } from "../../pages/3d";
import SkyObject from "./Sky";
import Ocean from "./Ocean";
import styles from "../../css/3d.module.css";
import data from "../../data/chapters";
import { typeface } from "../../assets";

const radius = 45;
const rotation = [];
export const positions = Array.from({ length: 5 }, (_, index) => {
    const angle = (index / 5) * 1.4 * Math.PI + 0.1;
    const x = radius * Math.cos(angle);
    const z = radius * Math.sin(angle);
    rotation.push([0, Math.PI / 2 - angle, 0]);
    return [x, -0.001, z];
});

const Scene = () => {
    const { enabled, cameraPosition, cameraTarget } = useStore((state) => state);
    const width = window.screen.width;
    const [Screenscale, setScreenscale] = useState([100, 100, 100]);
    const [BillboardScale, setBillboardScale] = useState([1.3, 1.3, 1.3])
    const [shipScale, setShipScale] = useState([0.5, 0.5, 0.5])
    const [shipPosition, setShipPosition] = useState([0, -0.4, 0])
    const billBoardRef = useRef()
    useEffect(() => {
        if (width < 500) {
            setBillboardScale([.8, .8, .8])
            setScreenscale([65, 65, 65])
            setShipScale([0.3, 0.3, 0.3])
            setShipPosition([0, 0.3, 1])
        } else {
            setScreenscale([100, 100, 100]);
            setBillboardScale([1.3, 1.3, 1.3])
            setShipScale([0.5, 0.5, 0.5])
            setShipPosition([0, -0.4, 0])
        }
    }, [width]);
    const [zoom, setZoom] = useState(false);
    const [focus, setFocus] = useState(true);
    const vec = new THREE.Vector3();
    const lookAtVec = useRef(new THREE.Vector3());
    const [currentData, setCurrentData] = useState(null);
    const texture = useTexture(typeface);

    useFrame((state, delta) => {
        const step = 0.8;
        if(billBoardRef.current){
            const direction = new THREE.Vector3();
            state.camera.getWorldDirection(direction);
    
            const distance = 50; 
            const billboardPosition = direction.multiplyScalar(distance);
            const target = new THREE.Vector3(
                state.camera.position.x + billboardPosition.x,
                state.camera.position.y + billboardPosition.y + 10,
                state.camera.position.z + billboardPosition.z
            )
    
            billBoardRef.current.position.lerp(target, 0.75 )
    
            billBoardRef.current.lookAt(state.camera.position)
        }
        if (zoom) {
            useStore.setState((state) => ({ enabled: false }));
            switch (focus.name) {
                case "america":
                    vec.set(14.75, 2, 15);
                    break;
                case "china":
                    vec.set(18.5, 2, -2)
                    break
                case "india":
                    vec.set(-3.7, 2, 17)
                    break
                case "europe":
                    vec.set(-15, 2, 8.5);
                    break;
                case "egypt":
                    vec.set(-15, 2, -5);
                    break;
                default:
                    vec.set(0, 0, 0);
            }
            state.camera.position.lerp(vec, delta * step)
            state.camera.lookAt(lookAtVec.current.lerp(new THREE.Vector3(focus.position.x, 8, focus.position.z), delta * step * 3))
            state.camera.updateProjectionMatrix()
            useStore.setState(() => ({ enabled: true }))
        } else {
            state.camera.lookAt(lookAtVec.current.lerp(cameraTarget, delta * step * 3));
            state.camera.position.lerp(cameraPosition, delta * step);
        }
    });

    const zoomToView = (focusRef) => {
        setZoom(!zoom)
        setFocus(focusRef.current)
        setCurrentData(data[focusRef.current.name])
    }

    return (
        <>
            <ambientLight intensity={1.25} />
            <directionalLight
                position={[0, 0.5, 0]}
                intensity={2.5}
                castShadow
            />
            {
                !zoom &&
                <Clouds material={THREE.MeshBasicMaterial}>
                    <Cloud segments={60} bounds={[200, 200, 200]} speed={0.1} volume={10} color="white" castShadow />
                </Clouds>
            }
            {
                !zoom &&
                <Billboard ref={billBoardRef} >
                    <mesh scale={BillboardScale}>
                        <planeGeometry args={[24, 6]} />
                        <meshBasicMaterial map={texture} transparent={true} />
                    </mesh>
                </Billboard>
            }
            <Sparkles count={50} scale={0.5} size={6} speed={0.4} />
            <Stars radius={100} depth={50} count={5000} factor={4} saturation={0} fade speed={1} />
            <SkyObject />
            <Ocean />
            <China position={positions[0]} rotation={rotation[0]} scale={Screenscale} zoomToView={zoomToView} />
            <America position={positions[1]} rotation={rotation[1]} scale={Screenscale} zoomToView={zoomToView} />
            <India position={positions[2]} rotation={rotation[2]} scale={Screenscale} zoomToView={zoomToView} />
            <Europe position={positions[3]} rotation={rotation[3]} scale={Screenscale} zoomToView={zoomToView} />
            <Egypt position={positions[4]} rotation={rotation[4]} scale={Screenscale} zoomToView={zoomToView} />
            <Ship position={shipPosition} scale={shipScale} />
            {zoom && (
                <>
                    <Html position={[vec]}>
                        <div
                            className={styles.container}
                        >
                            <div className={styles.overlay}>
                                <img src={typeface} alt="typeface" />
                                <h1 className={styles[focus.name]} >{currentData.heading}</h1>
                                <div className={styles.helper}>
                                    <div />
                                    <div />
                                    <div />
                                </div>
                                {currentData.body}
                                <button onClick={() => setZoom(!zoom)}>Go Back</button>
                            </div>
                        </div>
                    </Html>
                </>
            )}
        </>
    );
};

export default Scene;

import Navbar from "../components/Navbar"
import styles from "../css/LandingPage.module.css"
import typefacefinal from "../assets/title.png"
import { useNavigate } from "react-router-dom"

const LandingPage = () => {
    const navigate = useNavigate()
    return (
        <div className={styles.tempHome}>
        <div className={styles.bg}></div>
        <div className={styles.title}>
            <img className={styles.typeface} src={typefacefinal} alt="typeface" />
            <button className={styles.threeD} onClick={() => navigate("/3d")}>Embark on a 3D Adventure</button>
        </div>
        </div>
    )
}

export default LandingPage
import { BentoGrid } from "react-bento";
import styles from "../css/Gallery.module.css";
import AnimatedDiv from "./AnimatedDiv";
import Button from "./Button";
import React, { useState, useEffect } from 'react';

const Gallery = ({ data }) => {
  const [gridCols, setGridCols] = useState(3);
  const [imageCount, setImageCount] = useState(6);

  useEffect(() => {
    const updateGridSettings = () => {
      if (window.innerWidth <= 576) {
        setGridCols(2);
        setImageCount(5);
      } else {
        setGridCols(3);
        setImageCount(6);
      }
    };

    window.addEventListener("resize", updateGridSettings);
    updateGridSettings();

    return () => window.removeEventListener("resize", updateGridSettings);
  }, []);

  const bentoItems = data.slice(0, imageCount).map((item, index) => {
    let dimensions = { width: 1, height: 1 };

    if (index === 0) {
      dimensions = { width: 2, height: 1 };
    } else if (index === 1 || index === 3) {
      dimensions = { width: 1, height: 2 };
    } else if (index === 2 || index === 4) {
      dimensions = { width: 1, height: 1 };
    }

    return {
      id: index,
      element: (
        <div className={styles.imageContainer} key={index}>
          <img src={item.pic.content.url} alt="gallery" className={styles.imagess} />
        </div>
      ),
      width: dimensions.width,
      height: dimensions.height,
    };
  });

  return (
    <AnimatedDiv>
      <div className={styles.landinggallery}>
        <h1 className={styles.heading}>GALLERY</h1>
        <BentoGrid
          items={bentoItems}
          gridCols={gridCols}
          rowHeight={150}
          classNames={{
            container: styles.bentoContainer,
            elementContainer: styles.bentoElement,
          }}
        />
        <Button />
      </div>
    </AnimatedDiv>
  );
};

export default Gallery;

import { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { ship } from "../../assets";

export default function Ship({ position = [0, -0.4, 0], rotation = [-Math.PI/2, 0, 0], scale = [0.5, 0.5, 0.5] }) {
    const { nodes } = useGLTF(ship)
    const modelRef = useRef()

    return (
        <group ref={modelRef} position={position} rotation={rotation} scale={scale}>
            {
                Object.keys(nodes).map((key) => {
                    const node = nodes[key]
                    if (node.isMesh) {
                        return (
                            <mesh
                                key={key}
                                geometry={node.geometry}
                                material={node.material}
                                position={node.position}
                                scale={node.scale}
                            />
                        )
                    }
                })
            }
        </group>
    )
}
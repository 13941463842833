import React, { useState } from "react";
import styles from "../css/HOFCards.module.css";
import trophy from "../assets/HOF/png-transparent-trophy-trophy-image-file-formats-trophy-objects-thumbnail-removebg-preview 1.png";
import qmark from "../assets/HOF/image (1).png";

const HOFCards = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");

  const openModal = (src) => {
    setModalImageSrc(src);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImageSrc("");
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardImage}>
        <img
          src={data.pic.content.url}
          alt={data.title}
          className={styles.thumbnail} 
          onClick={() => openModal(data.pic.content.url)} 
        />
      </div>
      <div className={styles.outer}>
        <div className={styles.mark}>
          <img src={qmark} alt="Mark" />
        </div>
        <div className={styles.quote}>
          {data.quote.content}
        </div>
      </div>
      <div className={styles.prize}>
        <span className={styles.tr}>
          <img src={trophy} alt="Trophy" />
        </span>
        <span className={styles.specifics}>
          {data.prizes.content}
        </span>
      </div>
      <div className={styles.college} data-text={data.college.content}>
        {data.college.content}
      </div>

      {isModalOpen && (
        <div className={styles.modal}>
          <span className={styles.close} onClick={closeModal}>&times;</span>
          <img className={styles.modalContent} src={modalImageSrc} alt="Enlarged" />
        </div>
      )}
    </div>
  );
};

export default HOFCards;

import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import styles from '../css/Merchandise.module.css';
import styled from "styled-components";
import { Link } from "react-router-dom";
import Loader from './Loader';
import { Canvas } from '@react-three/fiber';
import Model from './Model';
import { getSingleDoc } from "../config/firebaseFirestore";
import modelMerch from '../assets/model/Merchandise.glb';
import { OrbitControls } from '@react-three/drei';

const Merchandise = () => {
    const [merch, setMerch] = useState(null); 
    const width = window.screen.width;
    const [Modelscale, setModelscale] = useState([7, 7, 7]);
    
    const [Position, setPosition] = useState([0,-10.2,0]);
    useEffect(() => {
        if (width < 500) {
            setModelscale([6,6,6]);
            setPosition([0,0,0])
        } else {
            setModelscale([8, 8, 8]);
            setPosition([0,-10.2,0])
        }
    }, [width]);
    const fetchMerch = async () => {
        try {
            const data = await getSingleDoc("WebContentsNew", "Merch_New");
            if (data) {
                console.log("Fetched data:", data);
                setMerch(data.data[0]);
            }
        } catch (error) {
            console.error("Error fetching merch:", error);
        }
    };

    useEffect(() => {
        fetchMerch();
    }, []);
    console.log(merch)
    
    if (!merch) {
        return <Loader/>; 
    }
    
    return (
        <div className={styles.container1}>
            <h1 className={styles.titlemerch}>Merchandise</h1>
            <div className={styles.containmerch}>
                <div className={styles.tshirts}>
                    <div className={styles.tshirtPlaceholder}>
                        <Canvas style={{ height: '400px', width: '100%' }}>
                            <ambientLight />
                            <pointLight position={[10, 10, 10]} />
                            <Model url={modelMerch} scale={Modelscale} position={Position} />
                            <OrbitControls 
                                maxPolarAngle={Math.PI/2} 
                                minPolarAngle={Math.PI/3} 
                                maxAzimuthAngle={Math.PI} 
                                minAzimuthAngle={-Math.PI } 
                                minDistance={5} 
                                maxDistance={5}
                            /> 
                        </Canvas>
                    </div>
                    <div className={styles.buttons}>
                    <Link to={merch.link.content} className={styles.buttonorder} id="view" target="_blank" rel="noopener noreferrer">
                        <span>Order Now</span>
                    </Link>
                    </div>
                </div>
                <div className={styles.instructions}>
                    <ReactMarkdown>{merch.desc.content}</ReactMarkdown>
                </div>
            </div>
        </div>
    );
};

const StyledWrapper = styled.div`
    .menu {
        display: flex;
        justify-content: center;
        gap: 16px;
        margin: 20px auto;
        padding: 10px;
        margin-bottom:0;
        width: 100%;
    }

    #view {
        margin-top: -15px;
    }

    .link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 4px 24px;
        border: 1px solid #ff8888;
        border-radius: 4px;
        font-size: 26px;
        color: #ff8888;
        text-decoration: none;
        transition: all 0.3s ease;
        cursor: pointer;
        text-align: center;
    }

    .link:hover {
        color: #ffffff;
        border-color: #ff8888;
        transform: translateY(-2px);
    }

    .link span {
        display: block;
        font-size: 16px;
    }

    @media (max-width: 768px) {
        .menu {
            flex-direction: row;
            gap: 12px;
        }

        .link {
            width: 80%;
            margin: 0 auto;
        }
    }

    @media (max-width: 480px) {
        .link {
            font-size: 10px;
            padding: 5px 10px;
        }
    }
`;

export default Merchandise;

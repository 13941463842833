import { useEffect, useState } from "react"
import AboutAntaragni from "../components/AboutAntaragni"
import DiscoverMore from "../components/DiscoverMore"
import ThemeReveal from "../components/ThemeReveal"
import styles from "../css/Home.module.css"
import { getSingleDoc } from "../config/firebaseFirestore"
import Gallery from "../components/Gallery"
import Loader from "../components/Loader"
import History from "../components/History"
import JoinCommunity from "../components/JoinCommunity"
import Footer from "../components/Footer"

const Home = () => {
    const [loading, setLoading] = useState(true)
    const [discoverData, setDiscoverData] = useState([])
    const [galleryData, setGalleryData] = useState([])

    const fetchData = async () => {
        setLoading(true)
        const rawData = await getSingleDoc("WebContentsNew", "applandingpagenew")
        let discover = []
        let gallery = []
        for (let i = 0; i < rawData.data.length; i++) {
            switch (rawData.data[i].vertical["content"]) {
                case "Discover More":
                    discover.push(rawData.data[i])
                    break
                case "Gallery":
                    gallery.push(rawData.data[i])
                    break
                default:
                    break
            }
        }
        setDiscoverData(discover)
        setGalleryData(gallery)
        setLoading(false)
    }
    useEffect(() => {
        fetchData().then(()=> setLoading(false))
    }, [])

    if (loading)
        return <Loader />
    return (
        <div className={styles.container}>
            <ThemeReveal />
            <AboutAntaragni />
            <DiscoverMore data={discoverData} />
            <Gallery data={galleryData} />
            {/* <History /> */}
            <div className={styles.gradientBackground}>
                <JoinCommunity className={styles.joinCommunity} />
                <Footer />
            </div>
        </div>
    )
}

export default Home
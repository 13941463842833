import { useRef } from "react"
import { motion, useInView } from "framer-motion"

const AnimatedDiv = ({children}) => {
    const ref = useRef(null)
    const isInView = useInView(ref)

    return (
        <motion.div
            ref={ref}
            initial={{opacity: 0}}
            animate={isInView ? {opacity: 1} : {}}
            transition={{duration: 1.5}}
        >
            {children}
        </motion.div>
    )
}

export default AnimatedDiv
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { grass } from '../../assets'

export default function Grass({ position, rotation = [0, 0, 0], scale = [0.009, 0.009, 0.009] }) {
  const { nodes, materials } = useGLTF(grass)
  const modelRef = useRef()
  return (
    <group ref={modelRef} position={position} rotation={[-Math.PI/2 + rotation[0], 0 + rotation[1], Math.PI/8 + rotation[2]]} scale={scale}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.grass001.geometry}
        material={materials.material_1_grass}
      />
    </group>
  )
}
import { Line, PerspectiveCamera, useProgress, useScroll } from "@react-three/drei"
import { useFrame, useThree } from "@react-three/fiber"
import * as THREE from "three"
import { positions } from "./Scene"
import { useEffect, useState } from "react"


const CameraControl = ({ cameraPosition, cameraTarget, psuedoCameraTarget, enabled }) => {
  const {progress} = useProgress()
  const [startTransition, setStartTransiiton] = useState(false)
  const scroll = useScroll();
  const cameraTargetCurve = new THREE.CatmullRomCurve3(
    positions.map((item) => (new THREE.Vector3(...item)))
  );
  const [hasMoved, setHasMoved] = useState(false)

  const { gl, camera} = useThree()

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth, innerHeight } = window;
      gl.setSize(innerWidth, innerHeight);
      camera.aspect = innerWidth / innerHeight;
      gl.setPixelRatio(window.devicePixelRatio)
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [gl, camera])

  useEffect(() => {
    if(progress === 100)
      setStartTransiiton(true)
  }, [progress])

  const cameraTargetPoints = cameraTargetCurve.getPoints(99);
  useFrame(({ camera }, delta) => {
    if (enabled) {
      let offset = parseInt(scroll.offset * (cameraTargetPoints.length - 1));
      cameraTarget.copy(cameraTargetPoints[offset]);
    }

  });

  return (
    <>
      {/* <Line points={cameraPositionPoints} lineWidth={1.5} color={"red"} /> */}
      {/* <Line points={cameraTargetPoints} lineWidth={1.5} color={"green"} /> */}
    </>
  );
}

export default CameraControl
import { useEffect, useRef, useState } from "react"
import Base from "./Base"
import LibertyStatue from "./LibertyStatue"
import Rio from "./Rio"
import Rock from "./Rock"
import Trees from "./Trees"
import Grass from './Grass'
import Bird from './Bird'
import ExploreButton from "./ExploreButton"
export default function America({ position = [0, 0, 0], rotation = [Math.PI/2, 0, 0], scale, zoomToView }) {
    const ref = useRef()
    const [clicked, setClicked] = useState(false)
    const [hovered, setHovered] = useState(false)

    useEffect(() => {
        document.body.style.cursor = hovered ? "pointer" : "auto"
    }, [hovered])

    return (
        <group name="america" position={position} rotation={rotation} scale={scale} onClick={() => {
            setClicked(!clicked)
            zoomToView(ref)
        }} ref={ref} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
            
            {!clicked && <ExploreButton />}
            <Base rotation={[-Math.PI/2,-Math.PI,-Math.PI/2 ]} scale={[0.0003, 0.0003, 0.0003]} />
            <LibertyStatue />
            <Bird rotation = {[Math.PI/2, - Math.PI/3, Math.PI/2]} scale={0.01} position = {[0.06, 0.03, -0.048]}/>
            <Rock position={[0.02,0.0,0.01]} scale={[0.0002, 0.0002, 0.0002]}/>
            <Grass scale = {[0.0003, 0.0003, 0.0003]} position={[- 0.03,  -0.004,- 0.05]} rotation={[ 0, Math.PI,  - Math.PI/2]}/>
            <Rock scale={[0.0002, 0.0002, 0.0002]} position={[-0.055, 0, -0.02]}/>
            <Rock scale={[0.0002, 0.0002, 0.0002]} position={[0.038, -0.01, -0.052]}/>
            <Trees rotation={[Math.PI/2.2, 0, -Math.PI/4]} position={[-0.03,-0.02,-0.02]} scale={0.00028}/>
            <Trees position={[0.05,-0.02,-0.005]} rotation={[Math.PI/3, 0, -Math.PI/4]} scale={0.00028}/>
            <Rio />
        </group>
    )
} 
import React from "react";
import styles from "../css/ContactCard.module.css";
import { BsLinkedin, BsTelephoneFill } from "react-icons/bs";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { FaSquareEnvelope, FaSquarePhone } from "react-icons/fa6";

const ContactCard = ({ member }) => {
  const isLongName = member.Name.length > 15;
  return (
    <div className={styles.cardContainer}>
      <div className={styles.profileDiv}>
        <img src={member.Pic.url} alt="Head" className={styles.organizerImage} />
      </div>
      <div className={styles.infoDiv}>
        <div className={styles.nameDiv}>
          <span className={`${styles.name} ${isLongName ? styles["name-small"] : ""}`}>
            {member.Name}
          </span>        </div>
        <div className={styles.socialDiv}>
          <a target="_blank" href={`tel:${member.Phone}`} className={styles.sociallink}>
            <FaSquarePhone className={styles.socialIcon} />
          </a>
          <a href={`mailto:${member.Email}`} className={styles.sociallink}>
            <FaSquareEnvelope className={styles.socialIcon} />
          </a>
          <a href={member.Instagram} className={styles.sociallink} target="_blank" rel="noopener noreferrer">
            <FaInstagramSquare className={styles.socialIcon} />
          </a>
          <a href={member.Facebook} className={styles.sociallink} target="_blank" rel="noopener noreferrer">
            <FaFacebookSquare className={styles.socialIcon} />
          </a>
          <a href={member.LinkedIn} className={styles.sociallink} target="_blank" rel="noopener noreferrer">
            <BsLinkedin className={styles.socialIcon} />
          </a>
        </div>
      </div>
    </div>
  );
};
export default ContactCard;
import React from "react";
import styles from "../css/sponsorCard.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const SponsorCard = ({ member }) => {
  // if (!member || !member.webLink || !member.img || !member.img.url || !member.desc) {
  //   return <div>Error: Invalid sponsor data</div>;
  // }

  console.log(member);

  return (
    <div className={`${styles.sponsors__imageDiv}`}>
      <a target="_blank" rel="noreferrer" href={member.weblink.content}>
        <img
          className={styles.sponsors__img}
          src={member.img.content.url}
          alt={member.name.content}
        />
        <p className={styles.sponsors__desc}>{member.desc.content}</p>
      </a>
    </div>
  );
};

export default SponsorCard;
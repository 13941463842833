import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { base } from '../../assets'

export default function Base({position=[0, 0, 0], rotation=[Math.PI/2, 0, 0], scale=[0.011, 0.011, 0.011]}) {
  const { nodes, materials } = useGLTF(base)
  const modelRef = useRef()
  return (
    <group ref={modelRef} position={position} rotation={rotation} scale={scale}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.terrain002.geometry}
        material={materials['material_1.002']}
      />
    </group>
  )
}
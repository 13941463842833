import React from "react";
import styles from "../css/organizerCard.module.css";
import { BsLinkedin } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";

function OrganizerCard({ organizer }) {
  const { Pic, Name, Contact, Email, Facebook, LinkedIn, Instagram } = organizer;

  return (
    <>
      <div className={styles.card}>
        <div className={styles.cardPhoto}>
          <img src={Pic.content.url} alt="Image" />
        </div>
        <div className={styles.cardTitle}>
          {Name.content}
          <br />
        </div>
        <div className={styles.para}>
          <br />
          {`+91-${Contact.content}`}
        </div>
        <div className={styles.cardSocials}>
          <button className={styles.cardSocialsBtn}>
            <a target="_blank" href={`mailto:${Email.content}`}>
              <HiOutlineMail size={35} color={"var(--color2)"} />
            </a>
          </button>
          <button className={styles.cardSocialsBtn}>
            <a target="_blank" href={`mailto:${Email.content}`}>
              <FaInstagramSquare size={35} color={"var(--color2)"} />
            </a>
          </button>
          <button className={styles.cardSocialsBtn}>
            <a target="_blank" href={LinkedIn.content}>
              <BsLinkedin size={30} color={"var(--color2)"} />
            </a>
          </button>
          <button className={styles.cardSocialsBtn}>
            <a target="_blank" href={Facebook.content}>
              <FaFacebookSquare size={35} color={"var(--color2)"} />
            </a>
          </button>
        </div>
      </div>
    </>
  );
};
export default OrganizerCard;

import { BrowserRouter, Routes, Route, useParams, useLocation } from "react-router-dom";
import styles from "../css/App.module.css";
import {
  LandingPage,
  Home,
  Sponsors,
  Media,
  CoreTeam,
  Queries,
  HallOfFame,
  Merch
} from "../pages";
import Navbar from "./Navbar";
import Attractions from "../pages/StarAttractions";
import Brochure from "../pages/Brochure";
import Loader from "./Loader";
import Gallery from "../pages/Gallery";
import ThreeD from "../pages/3d";
import ModeSwitch from "./ModeSwitch";
import Merchandise from "./Merchandise";
import Schedule from "../pages/schedule";
const App = () => {
  return (
    <div className={styles.outer_container}>
      <BrowserRouter>
        <Navbar />
        <ModeSwitch />
        <Routes>
          <Route exact path="/" element={<><LandingPage />
            <Home />
          </>}>
          </Route>
          <Route exact path="/sponsors" element={<Sponsors />}></Route>
          <Route exact path="/media" element={<Media />}></Route>
          <Route exact path="/coreTeam" element={<CoreTeam />}></Route>
          <Route exact path="/queries" element={<Queries />}></Route>
          <Route exact path="/brochure" element={<Brochure />}></Route>
          <Route exact path="/attractions" element={<Attractions />}></Route>
          <Route exact path="/gallery" element={<Gallery />}></Route>
          <Route exact path="/hof" element={<HallOfFame />}></Route>
          <Route exact path='merch' element={<Merch />}></Route>
          <Route exact path="/3d" element={<ThreeD />}></Route>
          <Route exact path="/schedule" element={<Schedule /> }></Route>
          {/* <Route exact path="/merchandise" element={<Media />}></Route> */}
          <Route
            exact
            path="*"
            element={<h1>Error 404: Page Not Found</h1>}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
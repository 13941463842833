import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { bird } from "../../assets";
export default function Bird({ position =[0.04, 0.03, -0.02], rotation = [Math.PI, 0, Math.PI], scale = [0.015, 0.015, 0.015] }) {
  const { nodes, materials } = useGLTF(bird)
  return (
    <group position={position} rotation={rotation} scale={scale} dispose={null}>
      <primitive object={nodes.GLTF_created_0_rootJoint} />
      <skinnedMesh
        geometry={nodes.Object_7.geometry}
        material={materials.jewel}
        skeleton={nodes.Object_7.skeleton}
        position={[-0.01, 1.075, -0.075]}
        rotation={[0.639, 0, 0]}
        scale={0.474}
      />
      <skinnedMesh
        geometry={nodes.Object_8.geometry}
        material={materials.jewel}
        skeleton={nodes.Object_8.skeleton}
        position={[-0.01, 1.075, -0.075]}
        rotation={[0.639, 0, 0]}
        scale={0.474}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_125.geometry}
        material={materials.jewel_flower}
        position={[0.249, 2.46, 0.087]}
        rotation={[-0.18, -0.075, 0.165]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_127.geometry}
        material={materials.jewel}
        position={[-0.005, 0.483, 0]}
      />
    </group>
  )
}
const data = {
    "america": {
        heading: "The Vibrant Worlds of America",
        body: `The ship crossed the Atlantic and reached the Americas, starting in the United States. The crew stood in awe of the Statue of Liberty and the Golden Gate Bridge, symbols of freedom and innovation. They explored the nation’s diverse culture, from technological advances to the arts. In Brazil, the vibrant spirit of Carnival welcomed them, along with the towering Christ the Redeemer statue. They danced to samba beats, embraced the country’s love for football, and appreciated the unique cultural fusion that defines Brazil.`,
        color: `#ff4500`,
        font: `"Bungee", sans-serif`
    },
    "europe": {
        heading: "The Enchanting Realms of Europe",
        body: `The crew's journey west led them to the heart of Europe, beginning in France. The iconic Eiffel Tower and Notre-Dame Cathedral filled the skyline, while ballet and the lively Can-can dances entertained them. They immersed themselves in Impressionist art and modern creativity. In Italy, they were transported to ancient times, standing before the Colosseum and marveling at Renaissance art. The Leaning Tower of Pisa and the exuberance of the Tarantella dance filled the crew with joy. In Spain, the vibrant colors of Flamenco and Gaudí's Sagrada Família left lasting impressions. They explored the works of Picasso and Dalí and enjoyed the traditions of bullfighting and lively fiestas.
`, 
        color: `#4169e1`,
        font: `"Righteous", sans-serif`
},
    "india": {
        heading: `The Soul of Antaragni - India`,
        body: `Finally, Antaragni’s ship arrived in India, where the crew felt a spiritual homecoming. In North India, the crew admired the splendor of the Ram Mandir, Qutub Minar, Statue Of Unity and the region’s rich history. In South India, the Meenakshi Temple and Mysore Palace stood as architectural wonders. In East India, they explored the Victoria Memorial and Howrah Bridge, dancing to Odissi and Bihu, and delving into the region’s vibrant literature and music. West India offered the majestic Gateway of India and Ajanta Caves, where ancient art merged with the modern energy of Mumbai. Throughout the country, the crew celebrated the deep spiritual diversity, festivals, and family values that define India.`,
        color: `#ff9933`,
        font: `"Lobster", cursive`
    },
    "egypt": {
        heading: `The Diverse Heart of Africa`,
        body: `Sailing south to Africa, the crew arrived in Egypt, where the majestic Pyramids and the Sphinx dominated the landscape. They participated in traditional folk dances and listened to the soothing melodies of the Oud and Ney. They marveled at the rich history and the vital role of the Nile in shaping Egyptian culture. The crew ventured through the Savannah, encountering breathtaking wildlife and exploring the diverse cultures that span from ancient times to the modern day.
`,  
        color: `#8b4513`,
        font: `"Fredoka One", sans-serif`
    },
    "china": {
        heading: `The Mystical Lands of Asia`,
        body: `Antaragni’s ship first reached Japan, where cherry blossoms painted the landscape in shades of pink. The crew marveled at the blend of traditional and modern architecture, from ancient pagodas to towering skyscrapers. They experienced the theatrical beauty of Kabuki and were serenaded by the gentle sounds of the Koto and Shakuhachi. Moving on to China, the crew stood in awe of the Great Wall and the Forbidden City. They took part in lively Dragon and Lion dances and admired Chinese calligraphy, ink-wash paintings, and the profound teachings of Confucius.
`,
        color: `#ffc0cb`,
        font: `"Pacifico", cursive`
    }

}

export default data
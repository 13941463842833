import { useEffect, useRef, useState } from "react"
import Base from "./Base"
import EgyptianGround from "./EgyptianGround"
import Grass from "./Grass"
import Rock from "./Rock"
import Shield from "./Shield"
import Sphinx from "./Sphinx"
import Trees from "./Trees"
import ExploreButton from "./ExploreButton"

export default function Egypt({ position = [0, 0, 0], rotation = [0, 0, 0], scale, zoomToView }) {
    const ref = useRef()
    const [clicked, setClicked] = useState(false)
    const [hovered, setHovered] = useState(false)

    useEffect(() => {
        document.body.style.cursor = hovered ? "pointer" : "auto"
    }, [hovered])
    return (
        <group name="egypt" position={position} rotation={rotation} scale={scale} ref={ref} onClick={() => {
            setClicked(true)
            zoomToView(ref)
        }} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
            {!clicked && <ExploreButton  position={[0.005, 0.003, -0.15]} rotation={[0, -Math.PI/0.9, 0]}/>}
            <Base position={[ 0.01, 0.001, - 0.04]} scale={0.0003} rotation={[Math.PI/2,0,Math.PI/2]}/>
            <Sphinx position={[ 0.03, -0.007, 0]} scale={0.003} rotation={[Math.PI/4, -Math.PI/3, Math.PI/3]}/>
            {/* Left Cactus */}
            <EgyptianGround position={[ 0.055, 0, -0.09]} rotation={[Math.PI/2,-Math.PI/2, Math.PI/2 ]} scale={0.01}/>
            <EgyptianGround position={[ 0.085, 0.01, -0.012]} rotation={[Math.PI/2 ,-Math.PI/2 - Math.PI/8, Math.PI/2 + Math.PI/24]} scale={0.005}/>
            <EgyptianGround position={[ 0.09, 0.001, -0.117]} rotation={[Math.PI/2+ Math.PI/20,-Math.PI/2, Math.PI/2 ]} scale={0.005}/>
            {/* Right Cactus */}
            <EgyptianGround position={[ -0.06, 0.012, -0.01]} rotation={[Math.PI/2,-Math.PI/2, Math.PI/2 + Math.PI/12]} scale={0.005}/>
            {/* Right rocks */}
            <Rock position={[-0.06, -0.0015, -0.1]} rotation={[Math.PI/2, 0,0]} scale={0.00015}/>
            <Rock position={[-0.05, -0.0015, -0.11]} scale={0.0001}/>
            <Rock position={[-0.04, -0.0015, -0.11]} scale={0.0001}/>
            {/* Left rocks */}
            <Rock position={[0.06, -0.0015, -0.1]} rotation={[Math.PI/2, 0,Math.PI/2]} scale={0.00008}/>
            <Rock position={[0.055, -0.0015, -0.08]} rotation={[Math.PI/2+Math.PI/20, 0,Math.PI/2]} scale={0.0001}/>
            <Rock position={[0.035, -0.0015, -0.12]} rotation={[Math.PI/2+Math.PI/20 , 0,Math.PI/2]} scale={0.0001}/>

            {/* <Rock position={[0.07, -0.0015, -0.10]} rotation={[Math.PI/2+Math.PI/20, 0,Math.PI/2]} scale={0.00015}/> */}
            {/* <Rock position={[0.065, -0.0015, -0.08]} rotation={[Math.PI/2+Math.PI/20, 0,Math.PI/2]} scale={0.0001}/> */}
            <Rock position={[0.055, -0.0015, -0.12]} rotation={[Math.PI/2+Math.PI/20 , 0,Math.PI/2]} scale={0.0001}/>

            {/* Behind Rocks */}
            <Rock position={[0.04, 0.026, 0]} rotation={[Math.PI/2+Math.PI/20, -Math.PI/12,Math.PI/2]} scale={0.00015}/>
            <Rock position={[-0.04, 0.006, -0.053]} rotation={[Math.PI/2+Math.PI/20, 0,Math.PI/2]} scale={0.0001}/>
            <Rock position={[-0.045, 0.006, -0.05]} rotation={[Math.PI/2+Math.PI/20 , 0,Math.PI/2]} scale={0.0001}/>

            {/* <Rock position={[0.045, 0.012, -0.04]} rotation={[Math.PI/2+Math.PI/20, -Math.PI/12,Math.PI/2]} scale={0.00015}/>
            <Rock position={[-0.04, 0.005, -0.063]} rotation={[Math.PI/2+Math.PI/20, 0,Math.PI/2]} scale={0.0001}/>
            <Rock position={[-0.045, 0.005, -0.06]} rotation={[Math.PI/2+Math.PI/20 , 0,Math.PI/2]} scale={0.0001}/>

            <Rock position={[0.065, 0.004, -0.085]} rotation={[Math.PI/2+Math.PI/20, -Math.PI/20,Math.PI/2]} scale={0.00015}/>
            <Rock position={[-0.05, 0.003, -0.073]} rotation={[Math.PI/2+Math.PI/20, 0,Math.PI/2]} scale={0.0001}/>
            <Rock position={[-0.055, 0.003, -0.07]} rotation={[Math.PI/2+Math.PI/20 , 0,Math.PI/2]} scale={0.0001}/> */}

            <Shield position={[ -0.035, 0.021, -0.105]} rotation={[Math.PI / 14 -Math.PI / 1.5 , Math.PI/0.5, Math.PI / 7 +Math.PI / 8]} scale={0.00009}/>
            {/* <Grass position={[ -0.01, -0.001, -0.115]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.018, -0.001, -0.10]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.02, -0.001, -0.115]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.04, -0.001, -0.115]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.01, -0.001, -0.105]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.019, -0.001, -0.09]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.02, -0.001, -0.105]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.03, -0.001, -0.105]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0., -0.001, -0.115]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.01, -0.001, -0.10]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.01, -0.001, -0.115]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.03, -0.001, -0.115]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0, -0.001, -0.105]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.018, -0.001, -0.09]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.01, -0.001, -0.105]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.02, -0.001, -0.105]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/> */}

            {/*Left Grass*/}
            {/* <Grass position={[ 0.045, -0.0018, -0.115]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/> */}
            {/* <Grass position={[ 0.045, -0.001, -0.10]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/> */}
            {/* <Grass position={[ 0.045, -0.0018, -0.125]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/> */}
            {/* <Grass position={[ 0.065, -0.001, -0.115]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00017}/> */}
            {/* <Grass position={[ 0.06, -0.001, -0.119]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/> */}
            {/*Right Grass*/}
            <Grass position={[ -0.03, -0.001, -0.115]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.03, -0.001, -0.10]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.03, -0.001, -0.115]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
            <Grass position={[ -0.05, -0.001, -0.115]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00017}/>
            <Grass position={[ -0.045, -0.001, -0.119]} rotation={[0, Math.PI, -Math.PI/2]} scale={0.00015}/>
           
            <Trees rotation={[Math.PI/2+Math.PI/12,0,Math.PI/2]} position={[ 0.01, -0.015, - 0.03]} scale={0.00035}/>
            
        </group>
    )
}
import { useEffect, useRef, useState } from "react"
import Base from "./Base"
import Bush1 from "./Bush1"
import Bush2 from "./Bush2"
import Grass from "./Grass"
import Rock from "./Rock"
import UnityStatue from "./UnityStatue"
import RamMandir from "./RamMandir"
import QutabMinar from './QutabMinar'
import ExploreButton from "./ExploreButton"
import Trees from "./Trees"
export default function India({ position = [0, 0, 0], rotation = [0, 0, 0], scale, zoomToView}) {
    const ref = useRef()
    const [clicked, setClicked] = useState(false)
    const [hovered, setHovered] = useState(false)

    useEffect(() => {
        document.body.style.cursor = hovered ? "pointer" : "auto"
    }, [hovered])
    return (
        <group name="india" position={position} rotation={[rotation[0], rotation[1] - Math.PI/2, rotation[2]]} scale={scale} ref={ref} onClick={() => {
            setClicked(!clicked)
            zoomToView(ref)
        }} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
            {!clicked && <ExploreButton position={[-0.15, 0.003, -0.005]} rotation={[0, -Math.PI/2, 0]}/>}

            <Rock scale={[0.0002, 0.0002, 0.0002]} position= {[0, -0.001, -0.03]} />
            <Rock scale={[0.0002, 0.0002, 0.0002]} position= {[-0.03, -0.004, 0.03]} />
            <Rock scale={[0.0002, 0.0001, 0.0002]} position= {[-0.04, -0.005, -0.06]} />
            <Base scale={0.0003} />
            <Grass position={[- 0.07,  -0.005,- 0.05]} rotation={[ 0, Math.PI,  - Math.PI/1.2]} scale = {[0.0002, 0.0002, 0.0002]}/>
            <Grass position={[- 0.07,  -0.005, 0.01]} rotation={[ 0, Math.PI,  - Math.PI/1.2]} scale = {[0.0002, 0.0002, 0.0002]}/>
            <Grass position={[- 0.07,  -0.004 , 0.04]} rotation={[ 0, Math.PI,  - Math.PI/1.2]} scale = {[0.0002, 0.0002, 0.0002]}/>
            <Bush1 />
            {/* <Bush2 /> */}
            <QutabMinar/>
            <UnityStatue position = {[0.12, -0.16, 0.15]} scale = {[0.0125, 0.0125, 0.0125]} />
            <RamMandir  scale = {[0.01, 0.01, 0.013]} position = {[-0.03, -0.0005, -0.004]}/>
            <Trees rotation={[Math.PI/2.2, 0, -Math.PI/0.8]} position={[0.04,-0.02,-0.03]} scale={0.00028}/>
            <Trees position={[-0.05,-0.02,0.05]} rotation={[Math.PI/2, 0, -Math.PI/2]} scale={0.00028}/>
            <Trees position={[0.05,-0.02,0.05]} rotation={[Math.PI/2, 0, -Math.PI/1.5]} scale={0.00028}/>
            <pointLight position = {[-0.099, 0.15, 0.09]} intensity={120} color="white"/>
        </group>
    )
}
import { useThree } from "@react-three/fiber";
import { useRef, useEffect } from "react";
import { Sky } from 'three/examples/jsm/objects/Sky'
import * as THREE from "three"

const SkyObject = () => {
    const { scene } = useThree()
    const skyRef = useRef()

    useEffect(() => {
        const sky = new Sky()
        sky.scale.set(1000, 1000, 1000)
        skyRef.current = sky
        scene.add(sky)

        const sun = new THREE.Vector3()
        const phi = THREE.MathUtils.degToRad(87)
        const theta = THREE.MathUtils.degToRad(180)
        sky.material.uniforms.turbidity.value = 20
        sky.material.uniforms.rayleigh.value = 3
        sky.material.uniforms.mieCoefficient.value = 0.001
        sky.material.uniforms.mieDirectionalG.value = 0.5

        sun.setFromSphericalCoords(1 ,phi, theta)
        sky.material.uniforms['sunPosition'].value.copy(sun)

    }, [scene])

    return null
}

export default SkyObject
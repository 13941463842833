import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "../css/Footer.module.css"
import { faFacebook, faInstagram, faLinkedin, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { Link } from "react-router-dom"
import title from "../assets/title.png"
import Associate from "./Associate"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <div className={styles.links}>
                    <div className={styles.item}>
                        <img src={title} alt="antaragni_logo" className={styles.img} />
                        <Link className={styles.listLink} to="https://events.antaragni.in/login/" target="_blank"><div className={styles.button}>Register Now</div></Link>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.text}>
                            <div className={styles.title}>
                                Participate
                            </div>
                            <div className={styles.list}>
                                <Link className={styles.listLink} to="https://events.antaragni.in/" target="_blank">Events Portal</Link>
                                <Link className={styles.listLink} to="https://ca.antaragni.in/" target="_blank">CA Portal</Link>
                                <Link className={styles.listLink} to="https://www.whatsapp.com/channel/0029Vak8LmD9mrGWHTsPIR3r" target="_blank">Join The Community</Link>
                            </div>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.text}>
                            <div className={styles.title}>
                                Explore More
                            </div>
                            <div className={styles.list}>
                                <Link className={styles.listLink} to="/attractions">Star Attractions</Link>
                                <Link className={styles.listLink} to="/hof">Hall Of Fame</Link>
                                <Link className={styles.listLink} to="/brochure">Brochure</Link>
                            </div>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.text}>
                            <div className={styles.title}>
                                Reach Out To Us
                            </div>
                            <div className={styles.list}>
                                {/* <Link className={styles.listLink} to="https://events.antaragni.in/login">Sign in</Link> */}
                                <Link className={styles.listLink} to="/queries">Have Any Queries?</Link>
                                <Link className={styles.listLink} to="/coreteam">Contact Us</Link>
                                <Associate />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.socials}>
                    {/* And in that moment, we all will become infinite */}
                    <div className={styles.icons}>
                        <Link to="https://whatsapp.com/channel/0029Vak8LmD9mrGWHTsPIR3r" target="_blank">
                            <FontAwesomeIcon icon={faWhatsapp} className={styles.faIcon} />
                        </Link>
                        <Link to="https://www.instagram.com/antaragni.iitkanpur/" target="_blank">
                            <FontAwesomeIcon icon={faInstagram} className={styles.faIcon} />
                        </Link>
                        <Link to="https://twitter.com/antaragni" target="_blank">
                            <FontAwesomeIcon icon={faXTwitter} className={styles.faIcon} />
                        </Link>
                        <Link to="https://www.youtube.com/user/antaragniiitkanpur" target="_blank">
                            <FontAwesomeIcon icon={faYoutube} className={styles.faIcon} />
                        </Link>
                        <Link to="https://www.linkedin.com/company/antaragni-iit-kanpur/mycompany/" target="_blank">
                            <FontAwesomeIcon icon={faLinkedin} className={styles.faIcon} />
                        </Link>
                        <Link to="https://www.facebook.com/antaragni.iitk/" target="_blank">
                            <FontAwesomeIcon icon={faFacebook} className={styles.faIcon} />
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.bottom}>
                <p className={styles.item}> &copy;Antaragni'24, IIT Kanpur</p>
                <p className={styles.item}>Made with &hearts; by Web Team</p>
                <p className={styles.item}> All Rights Reserved.</p>
                {/* <Link className={styles.item}>Terms and Conditions</Link>
                <Link className={styles.item}>Get the App</Link> */}
            </div>
        </div>
    )
}

export default Footer
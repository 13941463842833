import { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { ram } from "../../assets";
import { MeshStandardMaterial } from "three";

export default function RamMandir({ position = [-0.05, 0.005, 0], rotation = [Math.PI/2, Math.PI, -Math.PI/2], scale = [0.008, 0.008, 0.008] }) {
    const { nodes } = useGLTF(ram);
    const modelRef = useRef();

    const brownMaterial = new MeshStandardMaterial({ color: 0xD7A9A1 }); 

    return (
        <group ref={modelRef} position={position} rotation={rotation} scale={scale}>
            {
                Object.keys(nodes).map((key) => {
                    const node = nodes[key];
                    if (node.isMesh) {
                        return (
                            <mesh
                                key={key}
                                geometry={node.geometry}
                                material={brownMaterial}
                                position={node.position}
                                scale={node.scale}
                            />
                        );
                    }
                    return null; 
                })
            }
        </group>
    );
}

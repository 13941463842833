import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { pyramid } from '../../assets'

export default function EgyptianGround({ position = [0, 0.01, -0.05], rotation = [-Math.PI/2 , 0, Math.PI ], scale = [0.018, 0.018 , 0.018  ] }) {
  const { nodes, materials } = useGLTF(pyramid)
  const modelRef = useRef()
  return (
    <group ref={modelRef} position={position} rotation={rotation} scale={scale}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_2.geometry}
        material={materials.Cactus}
        rotation={[-Math.PI / 2, 0, 0]}
      />
    </group>
  )
}
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = () => {
  return (
    <StyledWrapper>
      <div className="menu">
        <Link to="/media" className="link">
          <span>Media</span>
        </Link>
        <Link to="/gallery" className="link" id="view">
          <span>View More</span>
        </Link>
        <Link to="/brochure" className="link">
          <span>Brochure</span>
        </Link>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .menu {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin: 20px auto;
    padding: 10px;
    width: 100%;
  }

  #view{
    margin-top: -15px;
  }

  .link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 24px;
    border: 1px solid #ff8888;
    border-radius: 4px;
    font-size: 16px;
    // font-weight: bold;
    color: #ff8888;
    text-decoration: none;
    background-color: none;
    transition: all 0.3s ease;
    cursor: pointer;
    // min-width: 120px;
    text-align: center;
  }

  .link:hover {
    color: #ffffff;
    border-color: #ff8888;
    tranform: translateY(-2px); 
  }

  .link span {
    display: block;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    .menu {
      flex-direction: row;
      gap: 12px;
    }

    .link {
      width: 80%;
      margin: 0 auto;
    }
  }

  @media (max-width: 480px) {
    .link {
      font-size: 10px;
      padding: 5px 10px;
    }
  }
`;

export default Button;

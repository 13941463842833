import React from 'react';
import { useGLTF } from '@react-three/drei';
import { templechina } from '../../assets';

export default function ChinaTemple({ position = [0, 0.0095, -0.03]}) {
  const { nodes, materials } = useGLTF(templechina);
  return (
    <group dispose={null} position={position} >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Japanese_Shrine_Japanese_Shrine_Mat_0.geometry}
        material={materials.Japanese_Shrine_Mat}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.0035}
      />
    </group>
  );
}

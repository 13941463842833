// import { useRef } from "react";
// import { useGLTF } from "@react-three/drei";
// import { pumpkin } from "../../assets";

// export default function Pumpkin({ position = [0, 0, 0], rotation = [0, Math.PI, Math.PI/8 - 0.5], scale = [0.001, 0.001, 0.001] }) {
//     const { nodes } = useGLTF(pumpkin)
//     const modelRef = useRef()

//     return (
//         <group ref={modelRef} position={position} rotation={rotation} scale={scale}>
//             {
//                 Object.keys(nodes).map((key) => {
//                     const node = nodes[key]
//                     if (node.isMesh) {
//                         return (
//                             <mesh
//                                 key={key}
//                                 geometry={node.geometry}
//                                 material={node.material}
//                                 position={node.position}
//                                 scale={node.scale}
//                             />
//                         )
//                     }
//                 })
//             }
//         </group>
//     )
// }
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { pumpkin } from "../../assets";
export default function Pumpkin( {position = [0, 0, 0], rotation = [0, Math.PI, Math.PI/8 - 0.5] }) {
  const { nodes, materials } = useGLTF(pumpkin)
  return (
    <group position={position} rotation={rotation} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pumpkin_Mat1_0.geometry}
        material={materials['Mat.1']}
        scale={0.000076}
      />
    </group>
  )
}
import { useThree } from "@react-three/fiber"
import { useEffect, useRef } from "react"
import { Water } from "three/examples/jsm/objects/Water"
import * as THREE from "three"
import { waterNormal } from "../../assets"

function Ocean() {
    const {scene} = useThree()
    const waterRef = useRef()
    
    useEffect(() => {
        const waterGeometry = new THREE.PlaneGeometry(1000, 1000)

        const water = new Water(waterGeometry, {
            textureWidth: 512, 
            textureHeight: 512, 
            waterNormals: new THREE.TextureLoader().load(waterNormal, (texture) => {texture.wrapS = texture.wrapT = THREE.RepeatWrapping}),
            alpha: 1,
            sunDirection: new THREE.Vector3(),
            sunColor: 0x0000ff,
            waterColor: 0x00003f,
            distortionScale: 1.5, 
            fog: true,      
        })
        water.rotation.x = -Math.PI / 2
        waterRef.current = water
        scene.add(water)

        const animateWater = () => {
            water.material.uniforms['time'].value += 1.0 / 200.0
        }
        
        const frameId = requestAnimationFrame(function render() {
            animateWater()
            requestAnimationFrame(render)
        })

        return () => {
            cancelAnimationFrame(frameId)
        }
    }, [scene])

    return null
}


export default Ocean
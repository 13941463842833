import React, { useState, useEffect } from "react";
import styles from "../css/schedule.module.css";
import Navbar from "../components/Navbar";
import { getSingleDoc } from "../config/firebaseFirestore";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import bg_schedule_card from "../assets/bg_schedule_card.png";
import Loader from "../components/Loader";

const Schedule = () => {
  // const [schedule, setSchedule] = useState([]);
  const [currentDaySchedule, setCurrentDaySchedule] = useState([]);
  const [day1, setDay1] = useState([]);
  const [day2, setDay2] = useState([]);
  const [day3, setDay3] = useState([]);
  const [day4, setDay4] = useState([]);
  const [activeDay, setActiveDay] = useState(1);
  const [loading, setLoading] = useState(true);

  const dates = [
    { day: 1, date: "2024-10-17" },
    { day: 2, date: "2024-10-18" },
    { day: 3, date: "2024-10-19" },
    { day: 4, date: "2024-10-20" },
  ];

  const getCurrentISTDate = () => {
    const now = new Date();
    const offsetIST = now.getTime() + (now.getTimezoneOffset() * 60000) + (5.5 * 60 * 60 * 1000);
    const istDate = new Date(offsetIST);
    return istDate.toISOString().split('T')[0];
  };

  const getActiveDayByDate = () => {
    const today = getCurrentISTDate();
    const activeDate = dates.find(dateObj => dateObj.date === today);
    return activeDate ? activeDate.day : 1;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSingleDoc("WebContentsNew", "schedule");
        if (data !== null && data.data) {
          const fetchedSchedule = data.data;
          const day1array = [];
          const day2array = [];
          const day3array = [];
          const day4array = [];
          console.log(data)
          console.log(fetchedSchedule)
          fetchedSchedule.forEach((event) => {
            const startTime = new Date(event.startTime);
            const endTime = new Date(event.endTime);
            const scheduleEvent = {
              title: event.eventName.content,
              description: event.desc.content,
              venue: event.venue.content,
              startTime: event.startTime.content.toDate(),
              endTime: event.endTime.content.toDate(),
              latitude: event.latitude.content,
              longitude: event.longitude.content,
              bgImage: event.image.content.url,
              day: event.day.content,
              timestamp: event.startTime.content.toDate(),
              start: event.startTime.content.toDate().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
              end: event.endTime.content.toDate().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
            };


            if (scheduleEvent.day === "1") day1array.push(scheduleEvent);
            else if (scheduleEvent.day === "2") day2array.push(scheduleEvent);
            else if (scheduleEvent.day === "3") day3array.push(scheduleEvent);
            else if (scheduleEvent.day === "4") day4array.push(scheduleEvent);
          });

          day1array.sort((x, y) => x.timestamp - y.timestamp);
          day2array.sort((x, y) => x.timestamp - y.timestamp);
          day3array.sort((x, y) => x.timestamp - y.timestamp);
          day4array.sort((x, y) => x.timestamp - y.timestamp);

          setDay1(day1array);
          setDay2(day2array);
          setDay3(day3array);
          setDay4(day4array);
          setCurrentDaySchedule(day1array);
        } else {
          console.error("No data available.");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
    setActiveDay(getActiveDayByDate());
  }, []);

  // console.log(title)

  useEffect(() => {
    if (activeDay === 1) setCurrentDaySchedule(day1);
    else if (activeDay === 2) setCurrentDaySchedule(day2);
    else if (activeDay === 3) setCurrentDaySchedule(day3);
    else if (activeDay === 4) setCurrentDaySchedule(day4);
  }, [activeDay, day1, day2, day3, day4]);

  const changeDay = (day) => setActiveDay(day);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.scheduleMain}>
      <Navbar />
      <div className={styles.appSchedule}>
        <div className={styles.scheduleHeading}>Schedule</div>
        <div className={styles.scheduleDays}>
          <button
            className={activeDay === 1 ? `${styles.activeDay} ${styles.scheduleDay}` : styles.scheduleDay}
            onClick={() => changeDay(1)}
          >
            <h3>Day 1</h3>
          </button>
          <button
            className={activeDay === 2 ? `${styles.activeDay} ${styles.scheduleDay}` : styles.scheduleDay}
            onClick={() => changeDay(2)}
          >
            <h3>Day 2</h3>
          </button>
          <button
            className={activeDay === 3 ? `${styles.activeDay} ${styles.scheduleDay}` : styles.scheduleDay}
            onClick={() => changeDay(3)}
          >
            <h3>Day 3</h3>
          </button>
          <button
            className={activeDay === 4 ? `${styles.activeDay} ${styles.scheduleDay}` : styles.scheduleDay}
            onClick={() => changeDay(4)}
          >
            <h3>Day 4</h3>
          </button>
        </div>

        <VerticalTimeline>
          {currentDaySchedule.map((event, index) => (
            <VerticalTimelineElement
              key={index}
              iconStyle={{ background: 'var(--secondary-color)', color: '#fff' }}
              icon={<i className="fas fa-calendar-alt"></i>}
              className={index % 2 === 0 ? styles.timelineLeft : styles.timelineRight}
            >
              <div className={styles.timelineContainer}>
                <div className={styles.scheduleCard}>
                  <img
                    src={bg_schedule_card}
                    alt="Background"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: -0,
                    }}
                  />
                  <div className={styles.scheduleContent} style={{ position: 'relative', zIndex: 2 }}>

                    <h3 className={styles.scheduleTitle}>{event.title}</h3>
                    <div className={styles.scheduleVenue}>{event.venue}</div>
                    <button
                      className={styles.scheduleMapbutton}
                      onClick={() => {
                        const mapsUrl = `https://www.google.com/maps?q=${event.latitude},${event.longitude}`;
                        window.open(mapsUrl, "_blank");
                      }}
                    >
                      Open in Map
                    </button>
                    <div className={styles.scheduleTime}>{event.start}</div>
                    <div className={styles.scheduleDesc}>{event.description}</div>
                  </div>
                </div>
                {index % 2 === 0 ? (
                  <div className={styles.leftEmptySpace}>
                    <img
                      src={event.bgImage}
                      alt={event.title}
                      className={styles.emptySpaceImage}
                    />
                  </div>
                ) : (
                  <div className={styles.rightEmptySpace}>
                    <img
                      src={event.bgImage}
                      alt={event.title}
                      className={styles.emptySpaceImage}
                    />
                  </div>
                )}
              </div>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Schedule;

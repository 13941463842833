import { useEffect, useRef, useState } from "react"
import Base from "./Base"
import Eiffel from "./Eiffel"
import Pumpkin from "./Pumpkin"
import Trees from "./Trees"
import Grass from "./Grass"
import Rock from "./Rock"
import ExploreButton from "./ExploreButton"
export default function Europe({ position = [0, 0, 0], rotation = [0, 0, 0], scale, zoomToView }) {
    const ref = useRef()
    const [clicked, setClicked] = useState(false)
    const [hovered, setHovered] = useState(false)

    useEffect(() => {
        document.body.style.cursor = hovered ? "pointer" : "auto"
    }, [hovered])
 
    return (
        <group name="europe" position={position} rotation={rotation} scale={scale} ref={ref} onClick={() => {
            setClicked(true)
            zoomToView(ref)
        }} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
            {!clicked && <ExploreButton  position={[0.005, 0.003, -0.15]} rotation={[0, -Math.PI/0.9, 0]}/>}
            <Base scale={[0.0003, 0.0003, 0.0003]} rotation={[-Math.PI/2,-Math.PI,-Math.PI/2 ]} />
            <Eiffel />
            <pointLight position={[0, 0.04, -0.03]} intensity={100} color="gold"/>
            <pointLight position={[-0.01, 0.08, -0.03]} intensity={50} color="gold"/>
            <Rock scale={[0.0002, 0.0002, 0.0002]} position={[-0.055, 0, -0.02]}/>
            <Rock scale={[0.0002, 0.0002, 0.0002]} position={[0.035, 0, -0.02]}/>
            <Rock scale={[0.0002, 0.0002, 0.0002]} position={[0.038, -0.01, -0.052]}/>
            <Trees rotation={[Math.PI/2.2, 0, -Math.PI/4]} position={[-0.03,-0.02,-0.02]} scale={0.0003}/>
            <Trees position={[0.05,-0.02,-0.005]} rotation={[Math.PI/3, 0, -Math.PI/4]} scale={0.0003}/>
            <Pumpkin position={[0.045, -0.002, -0.065]}/>
            <Pumpkin position={[0.04, 0.01, -0.02]}/>
            <Pumpkin position={[-0.05, 0.005, -0.05]}/>
        </group>
    )
} 
import React from 'react'
import Merchandise from '../components/Merchandise'

function Merch() {
  return (
    <div>
        <Merchandise/>
    </div>
  )
}

export default Merch
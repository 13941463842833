import { useEffect, useRef, useState } from "react"
import Base from "./Base"
import Confetti from "./Confetti"
import Lion from "./Lion"
import Rock from "./Rock"
import Trees from "./Trees"
import Grass from './Grass'
import ChinaTemple from './ChinaTemple'
import ExploreButton from "./ExploreButton"
export default function China({ position = [0, 0, 0], rotation = [0, 0, 0], scale, zoomToView }) {
    const ref = useRef()
    const [clicked, setClicked] = useState(false)
    const [hovered, setHovered] = useState(false)
    useEffect(() => {
        document.body.style.cursor = hovered ? "pointer" : "auto"
    }, [hovered])
    return (
        <group name="china" ref={ref} position={position} rotation={rotation} scale={scale} onClick={() => {
            setClicked(!clicked)
            zoomToView(ref)
        }} onPointerEnter={() => setHovered(true)} onPointerLeave={() => setHovered(false)}>
            {!clicked && <ExploreButton />}
            <Base scale={0.0003} rotation={[-Math.PI/2,-Math.PI,-Math.PI/2 ]} />
            <Lion  position = {[0.06, 0, -0.03]} scale = {[0.0005, 0.0005, 0.0005]} />
            <Rock position= {[0.02, -0.0001, -0.005]} scale={[0.00015, 0.00015, 0.00015]}/>
            {/* <Umbrella position = {[-0.05, 0.004, -0.09]} /> */}
            <ChinaTemple scale={0.0003}/>
            <Grass position={[- 0.055,  -0.0025,- 0.05]} rotation={[ 0, Math.PI,  - Math.PI/1.2]} scale = {[0.0002, 0.0002, 0.0002]}/>
            <Trees  scale={0.00025}/>
            <Trees  position={[0.05,0,-0.005]} scale={0.00025}/>
            <Confetti position= {[-0.05, 0.02, -0.13]} scale= {[0.004, 0.004, 0.004]} />
            <pointLight position = {[0.06, 0.03, -0.0539]} intensity={10} color=""/>
        </group>
    )
}
import { Link } from "react-router-dom"
import styles from "../css/JoinCommunity.module.css"
import AnimatedDiv from "./AnimatedDiv"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {faGooglePlay} from "@fortawesome/free-brands-svg-icons";

const JoinCommunity = () => {
    return (
        <AnimatedDiv>
            <div className={styles.container}>
                <div className={styles.title}>
                    JOIN THE COMMUNITY
                </div>
                <div className={styles.content}>
                    <div className={styles.vert1}></div>
                    <div className={styles.vert2}></div>
                    <div className={styles.hori1}></div>
                    <div className={styles.hori2}></div>
                    <div className={styles.text}>
                        <div className={styles.subtext}>
                        <b>Welcome to Antaragni - IIT Kanpur's Cultural Fest!</b>
                        <br/><br/><i>Stay tuned for all the latest updates, event announcements, and exclusive content related to Antaragni, our annual cultural extravaganza. Join us in celebrating creativity, talent, and the vibrant spirit of campus life. Don't miss out on any of the action. This is where the magic happens!</i>
                        </div>
                        <div className={styles.buttons}>
                            <Link className={styles.button} to="https://play.google.com/store/apps/details?id=antaragni.flame" target="_blank">
                                <FontAwesomeIcon icon={faGooglePlay} className={styles.faicon} />
                                Get the App
                            </Link>
                            <Link className={styles.button} to="https://www.whatsapp.com/channel/0029Vak8LmD9mrGWHTsPIR3r">
                                <FontAwesomeIcon icon={faWhatsapp} className={styles.faicon1} />
                                Join WhatsApp Channel
                            </Link>
                        </div>
                    </div>
                    <div className={styles.image}></div>
                </div>
            </div>
        </AnimatedDiv>
    )
}

export default JoinCommunity
import React from 'react';
import styles from '../css/mediaCard.module.css';

const MediaCard = (props) => {
 console.log(props.data);
  return (
      <div className={styles.cardarea}>
        <div className={styles.content}>
          <div className={styles.desc}>{props.data.desc.content}</div>
          <div className={styles.button}>
            <a href={props.data.link.content} rel="noreferrer" target="_blank">Read more</a>
          </div>
        </div>
        <div 
          className={styles.bgImage}
          style={{
            backgroundImage: `url(${props.data.img.content.url})`,
            height: '100%',
            width: '35%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundSize: 'contain',
            borderRadius: '5px',
            backgroundPosition: 'center',
            borderTop: '0px',
            alignContent: 'center',
            backgroundRepeat: 'no-repeat',
            border: '0px',
            marginRight: '0px',
          }}
        ></div>
      </div>
    );
}

export default MediaCard;
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { qutub }  from '../../assets'
export default function QutabMinar(props) {
  const { nodes, materials } = useGLTF(qutub)
  return (
    <group position={[-0.05,0,-0.055]} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.object_0.geometry}
        material={materials.object_0}
        // position={[0, 0, 8.28]}
        scale={0.00010}
      />
    </group>
  )
}
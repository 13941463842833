import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { shield } from '../../assets'

export default function Shield({ position = [-0.03, 0.015, -0.1], rotation = [-Math.PI / 5 -Math.PI / 1.5 , Math.PI/0.5, Math.PI / 7 +Math.PI / 8], scale = [0.0001, 0.0001, 0.0001] }) {
  const { nodes, materials } = useGLTF(shield)
  const modelRef = useRef()
  return (
    <group ref={modelRef} position={position} rotation={rotation} scale={scale}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.defaultMaterial.geometry}
        material={materials.RomanBuildingShield}
      />
    </group>
  )
}
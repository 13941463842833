import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { trees } from '../../assets'

export default function Trees({position=[0, 0, -0.02], rotation=[Math.PI/2, 0, -Math.PI/3], scale=[0.01, 0.01, 0.01]}) {
  const { nodes, materials } = useGLTF(trees)
  const modelRef = useRef()
  return (
    <group ref={modelRef} position={position} rotation={rotation} scale={scale}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.palm_leaves001.geometry}
        material={materials['material_1_palm_leaves.003']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.palm_stems001.geometry}
        material={materials['material_1_palm_stems.003']}
      />
    </group>
  )
}
import React from "react";
import styles from "../css/HalllOfFame.module.css"
import Footer from "../components/Footer";
import HOF from "../assets/HOF/Hall of Fame.png";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar.js";
import { getSingleDoc } from "../config/firebaseFirestore";
import { useState, useEffect } from "react";
import HOFCards from "../components/HOFCards.js";
const HallOfFame = () => {
  const [hof, setHallOfFame] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchHallOfFame = async () => {
    try {
      let data = await getSingleDoc("WebContentsNew", "HallOfFame");
      if (data !== null) {
        setHallOfFame(data.data);
        setLoading(false);
        // console.log(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchHallOfFame();
  }, []);

  if (loading) {
    return <Loader />;
  }

    return(
        <div className={styles.outer}>
            <div className={styles.top}>
    
                <div className={styles.h}>𝐀𝐍𝐓𝐀𝐑𝐀𝐆𝐍𝐈'𝟐𝟒</div>
                <div className={styles.hof}><img src={HOF}></img></div>
                <div className={styles.bar}>
                <div className={styles.line}></div>
                <div className={styles.circle}></div>
                <div className={styles.line}></div>
                </div>
                <div className={styles.quote}>“𝕮𝖊𝖑𝖊𝖇𝖗𝖆𝖙𝖎𝖓𝖌 𝖙𝖍𝖊 𝕭𝖗𝖎𝖑𝖑𝖎𝖆𝖓𝖈𝖊 𝖔𝖋 𝕿𝖆𝖑𝖊𝖓𝖙 𝖆𝖓𝖉 𝕮𝖗𝖊𝖆𝖙𝖎𝖛𝖎𝖙𝖞”</div>
                {/* <div className={styles.text}>Welcome to the Antaragni '23 Hall of Fame, where creativity and passion shine brighter than ever. This is a tribute to the exceptional talent and unwavering dedication that have made their mark on this prestigious stage. 
                    Each performance and artwork here is a beacon of inspiration for future generations.</div> */}
                    
            </div>
            <div className={styles.container}>
          {hof
            .map((e) => {
              return <HOFCards data={e} />
            })
          }



        </div>
            
        </div>
        
    );
};
export default HallOfFame;
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { rock } from '../../assets'

export default function Rock({position=[0, 0.01, -0.03], rotation=[Math.PI/2, 0, 0], scale=[0.009, 0.009, 0.009]}) {
  const { nodes, materials } = useGLTF(rock)
  const modelRef = useRef()
  return (
    <group ref={modelRef} position={position} rotation={rotation} scale={scale}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rocks002.geometry}
        material={materials['material_1_rocks.002']}
      />
    </group>
  )
}
import { Canvas } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera, ScrollControls } from "@react-three/drei";
import { Suspense, useEffect, useState } from "react";
import Scene from "../components/3d/Scene";
import * as THREE from "three"
import CameraControl from "../components/3d/CameraControls";
import { create } from 'zustand';
import styles from "../css/3d.module.css"
import typefacefinal from "../assets/title.png"
import Loader from "../components/Loader";


export const useStore = create((set) => ({
    cameraPosition: new THREE.Vector3(0, 1.5, 2.5),
    cameraTarget: new THREE.Vector3(-6.536918278267119, -0.001, 39.46224397349012),
    psuedoCameraTarget: new THREE.Vector3(-6.536918278267119, -0.001, 39.46224397349012),
    enabled: true
}));

const ThreeD = () => {
    const { cameraPosition, cameraTarget, psuedoCameraTarget, enabled } = useStore(
        (state) => state
    );

    const [showIntro, setShowIntro] = useState(true)
    const [hide, setHide] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            if (hide) {
                setShowIntro(false)
            }
        }, 2000)
    }, [hide])


    return (
        
        <Suspense fallback={<Loader />}>
            <div className={`${styles.intro} ${hide && styles.hide}`} style={{ display: showIntro ? "flex" : "none" }}>
                <div className={styles.bg}></div>
                <div className={styles.title}>
                    <img src={typefacefinal} alt="" />
                </div>
                <div className={styles.prologue}>
                    <div className={styles.heading}>Setting Sail</div>
                    <div className={styles.body}>
                        In an age long forgotten, a legendary ship known as Antaragni was crafted by the finest artisans from the realm of dreams. This ship was no ordinary vessel, imbued with the spirit of curiosity and unity, designed to traverse the vast and mysterious oceans in search of the world’s most profound cultures. The crew, a diverse and dedicated group symbolizing the spirit of Antaragni, stood ready at the helm, eager to embark on this unparalleled journey.
                        As dawn broke, the ship set sail from an unknown place in the ocean, shrouded in mist, guided by an ancient map that promised adventures across five great zones: Asia, Europe, Africa, America, and finally, their homeland, India. The crew, with hearts full of hope and minds open to learning, began their quest to embrace and celebrate the diversity of cultures around the world.
                    </div>

                    <div className={styles.go} onClick={() => setHide(true)}>Let's Go...</div>

                </div>
            </div>
                    <Canvas>
                        {/* <OrbitControls /> */}
                        <PerspectiveCamera makeDefault position={cameraPosition} />
                        <ScrollControls horizontal pages={5} damping={0.4} maxSpeed={0.8}>
                            <CameraControl cameraPosition={cameraPosition} cameraTarget={cameraTarget} psuedoCameraTarget={psuedoCameraTarget} enabled={enabled} />
                        </ScrollControls>
                        <Scene />
                    </Canvas>
                </Suspense>
            
    )
}

export default ThreeD
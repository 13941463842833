import { Box, Edges, RoundedBox, Text } from "@react-three/drei";

function ExploreButton({ position = [-0.005, 0.003, -0.15], rotation = [0, Math.PI, 0] }) {
    return (
        <group scale={[0.007, 0.007, 0.007]} position={position} rotation={rotation}>
            <RoundedBox
                args={[3.2, 1.6, 0.1]} 
                radius={0.2} 
                smoothness={4} 
                position={[0, 0, 0]} 
            >
                <meshStandardMaterial color="white" transparent opacity={0.8} />
            </RoundedBox>

            <Text
                position={[0, 0, 0.3]}  
                fontSize={0.7}
                color={0x000}  
                anchorX="center"  
                anchorY="middle"  
            >
                Explore
            </Text>
        </group>
    );
}

export default ExploreButton